<template>
  <!-- 标题组件 -->
  <div class="title-bar">
    <div class="title-head">
      <!-- <div class="left-line"></div> -->
      <div class="title-name" v-if="tState">
        <span style="font-size: 27px" class="tc">{{ title }}</span
        ><span style="font-size: 27px">{{ title1 }}</span>
        <!-- <span class="sub-title">{{ subTitle }}</span> -->
        <slot name="subTitle"></slot>
        <!-- 实际使用
          <template v-slot:subTitle>
            <span></span>   
          </template>
        -->
      </div>
      <div class="title-name" v-else>
        <span style="font-size: 27px">{{ title }}</span>
        <span style="font-size: 27px" class="tc">{{ title1 }}</span>
        <!-- <span class="sub-title">{{ subTitle }}</span> -->
        <slot name="subTitle"></slot>
        <!-- 实际使用
          <template v-slot:subTitle>
            <span></span>   
          </template>
        -->
      </div>
      <div>
        <span class="title-f">{{ title2 }}</span>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    tState: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "", //title名字
    },
    title1: {
      type: String,
      default: "", //title名字
    },
    title2: {
      type: String,
      default: "", //title名字
    },
    subTitle: {
      type: String,
      default: "", //title名字
    },
  },
};
</script>

<style lang="less" scoped>
.title-f {
  font-family: MicrosoftYaHei;
  color: #b4b4b4;
  padding: 5px;
  border-bottom: 2px solid #1ab394;
  display: inline-block;
}
.title-bar {
  margin-bottom: 26px;
  //   display: flex;
  justify-content: space-between;
  align-content: center;
  .title-bar-left {
    text-align: center;
  }
  .title-head {
    width: 350px;
    border-bottom: 1px solid #b4b4b4;
    text-align: center;
    margin: 0 auto;
  }
  .left-line {
    width: 6px;
    height: 24px;
    background: #2e8cf0;
    border-radius: 0px 3px 3px 0px;
    margin-right: 10px;
  }
  .title-name {
    font-size: 18px;
    font-weight: bold;
    color: #666666;
  }
  .sub-title {
    font-size: 14px;
    color: #ff4e4e;
  }
}
</style>