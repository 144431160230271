<template>
  <div class="imgT">
    <img :src="img" :style="{ width: width + 'px' }" alt="" />
  </div>
</template>
<script>
export default {
  props: {
    img: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 130,
    },
  },
};
</script>
<style lang="less" scoped>
.imgT :hover {
  transform: scale(1.2);
  // transform: scaleX(30px);
  // img {
  //   width: 130px;
  // }
}
.imgT img {
  transition: all 0.6s;
}
</style>