<template>
  <div class="bigdiv">
    <div class="block">
      <!-- 轮博图 -->
      <el-carousel trigger="click" height="350px">
        <el-carousel-item v-for="item in scrImg" :key="item">
          <!-- object-fit: contain -->
          <img style="width: 100%; height: 350px" :src="item" alt :onerror="smallImgError"/>
        </el-carousel-item>
      </el-carousel>
      <!-- 弹窗 -->
      <el-dialog title="高考信息" center :visible.sync="dialogVisible" width="30%">
        <el-form ref="form" :model="userForm" label-width="80px">
          <el-form-item label="高考省份">
            <el-select v-model="userForm.examProvince" placeholder="请选择" disabled>
              <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="高考年份">
            <el-select v-model="userForm.graduateYear" placeholder="请选择" disabled>
              <el-option v-for="item in options" :key="item.value" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="高考科别" v-if="userForm.disciplineType === 3 || userForm.disciplineType === 4">
            <el-radio v-model="userForm.disciplineType" :label="3">物理类</el-radio>
            <el-radio v-model="userForm.disciplineType" :label="4">历史类</el-radio>
          </el-form-item>
          <el-form-item label="高考科别" v-if="userForm.disciplineType === 1 || userForm.disciplineType === 2">
            <el-radio v-model="userForm.disciplineType" :label="1">理科</el-radio>
            <el-radio v-model="userForm.disciplineType" :label="2">文科</el-radio>
          </el-form-item>
          <el-form-item label="高考科别" v-if="userForm.disciplineType === 0">
            <el-radio v-model="userForm.disciplineType" :label="0">不分文理</el-radio>
          </el-form-item>
          <el-form-item label="再选科目" v-if="
            userForm.disciplineType === 3 ||
            userForm.disciplineType === 4 ||
            userForm.disciplineType === 0
          ">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="物理" v-if="userForm.disciplineType === 0">物理</el-checkbox>
              <el-checkbox label="历史" v-if="userForm.disciplineType === 0">历史</el-checkbox>
              <el-checkbox label="化学">化学</el-checkbox>
              <el-checkbox label="生物">生物</el-checkbox>
              <el-checkbox label="政治">政治</el-checkbox>
              <el-checkbox label="地理">地理</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="高考分数">
            <el-input v-model="userForm.score" placeholder="请输入分数(预估)"></el-input>
          </el-form-item>
          <el-form-item label="高考排名">
            <el-input :disabled="!!accumulate" v-model="userForm.accumulate" placeholder="请输入高考排名(预估)">
            </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveStudentInfo">确 定</el-button>
        </span>
      </el-dialog>
      <div class="top-mask" style="display:none" v-if="userId">
        <el-card shadow="hover" class="diag box-card windows">
          <div class="nav-mask-box">
            <h3 style="text-align: center;margin: 10px 0;">基本信息</h3>
            <div class="windows-1">
              <div class="up" style="width: 100%">
                <div>
                  <span class="white-text">高考省份</span>
                  <span class="white-text">{{ userForm.examProvince }}</span>
                  <span class="white-text" style="width: 30%; cursor: pointer" @click="changeScore">
                    <img style="vertical-align: middle; width: 14px" src="@/assets/update.png" alt=""/>
                    <span class="white-text"> 修改成绩 </span>
                  </span>
                </div>
                <div>
                  <span class="white-text">科目类型</span>
                  <span class="white-text" v-if="userForm.disciplineType === 3">物理类</span>
                  <span class="white-text" v-if="userForm.disciplineType === 4">历史类</span>
                  <span class="white-text" v-if="userForm.disciplineType === 1">理科</span>
                  <span class="white-text" v-if="userForm.disciplineType === 2">文科</span>
                  <span class="white-text" v-if="userForm.disciplineType === 0">不分文理</span>
                  <!-- <el-input></el-input> -->
                </div>
                <div>
                  <span class="white-text">选考科目</span>
                  <span class="white-text" style="width: 100px">{{ userForm.discipline }}</span>

                </div>
                <div>
                  <span class="white-text">高考总分</span>
                  <span class="white-text">{{ userForm.score }}分</span>

                </div>
                <div>
                  <span class="white-text">高考位次</span>
                  <span class="white-text">{{
                      userForm.scores.accumulate
                        ? userForm.scores.accumulate
                        : accumulate
                    }}名</span>
                </div>
              </div>
            </div>
            <el-button round @click="topschool()">开始推荐学校</el-button>
            <p @click="lookvolunteer()">>>查看志愿表</p>
          </div>
        </el-card>
      </div>
    </div>
    <div class="main">
      <div class="full-width">
        <div class="nav-2 main-content">
          <div class="person">
            <el-card style="cursor: pointer" shadow="hover" class="box-card hover" v-for="(item, index) in nav"
                     :key="index">
              <div @click="linkTo(index)" style="align-items: center">
                <imgT style="width: 95px; height: 95px; text-align: center" :width="95" :img="item.img_url"
                      :onerror="smallImgError" alt/>
                <p>{{ item.name }}</p>
              </div>
            </el-card>
          </div>
        </div>

      </div>
      <div class="divide-line"></div>
      <div class="full-width bg-img">
        <div class="nav-3 main-content pt38">
          <div>
            <TitleBar title="关于" :tState="false" title1="我们" title2="About us"></TitleBar>
            <div class="about-us-content">
              <div class="content-article" v-html="contentArticle"></div>
              <div class="content-img">
                <img class="img-box" v-if="contentImg" :src="contentImg" alt="" :onerror="smallImgError"
                     style="z-index: 999; height: 90%"/>
                <img src="@/assets/img-icon.png" class="img-box1" alt=""/>
                <img src="@/assets/img-icon1.png" class="img-box2" alt=""/>
                <!-- <img src="@/assets/矩形 43.png" class="img-box" alt="" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="divide-line"></div>
      <div class="full-width bg-img1">
        <div class="nav-4 main-content pt38">
          <TitleBar title="高考" title1="志愿服务" title2="提供个性化的高考志愿方案" :tState="false"></TitleBar>
          <img src alt/>

          <div class="f-row f-sa">
            <div class="swiper swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <el-card shadow="hover" style="align-items: center; margin: 0 auto; width: 390px" class="f-col"
                           :class="bg[0]">
                    <router-link to="/volunterservce/Comprehensive" :class="bg[0]">
                      <h2>兴趣性格能力测评服务</h2>
                      <p>兴趣性格能力测评服务</p>
                      <div>
                        <span>精准专业推荐>>></span>
                        <span>立即咨询</span>
                      </div>
                    </router-link>
                  </el-card>
                  <!-- </div> -->
                </div>
                <div class="swiper-slide" @click="goLink(1)">
                  <el-card shadow="hover" style="align-items: center; margin: 0 auto; width: 390px" class="f-col"
                           :class="bg[1]">
                    <router-link to="/volunterservce/Intelligentreporting">
                      <div :class="bg[1]">
                        <h2>模拟志愿填报服务</h2>
                        <p>大数据辅助志愿填报</p>
                        <div>
                          <span>600专家</span>
                          <span>立即咨询</span>
                        </div>
                      </div>
                    </router-link>
                  </el-card>
                </div>
                <div class="swiper-slide" @click="goLink(2)">
                  <el-card shadow="hover" style="align-items: center; margin: 0 auto; width: 390px" class="f-col"
                           :class="bg[2]">
                    <router-link to="/volunterservce/oneServe" :class="bg[2]">
                      <h2>志愿填报一对一服务</h2>
                      <p>大数据辅助志愿填报</p>
                      <div>
                        <span>600专家</span>
                        <span>立即咨询</span>
                      </div>
                    </router-link>
                  </el-card>
                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>
                <!-- 如果需要导航按钮 -->

                <!-- 如果需要滚动条 -->
                <!-- <div class="swiper-scrollbar"></div> -->
              </div>

              <!-- <div v-for="i in 3" :key="i">
            <p>兴趣性格能力测评服务</p>
            <p>兴趣性格能力测评服务</p>
            <div>
              <span>600专家</span>
              <span>立即咨询</span>
            </div>
          </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="divide-line"></div>

      <div class="full-width">
        <div class="nav-5 main-content mt38">
          <div>
            <TitleBar title="高考" title1="讲堂" title2="Company dynamics"></TitleBar>

            <div class="f-sb nav-h">
              <div style="width: 50%" class="f-sb lecture-hall">
                <el-card shadow="hover" class="box-card" v-for="(item, index) in lectures" :key="index">
                  <!-- <div v-for="o in 4" :key="o" class="text item">{{'列表内容 ' + o }}</div> -->
                  <div class="" @click="goMeeting(item)" v-if="index < 3">
                    <div class="img"></div>
                    <div style="margin-left: 55px">
                      <h3 class="f-s title">
                        {{ item.lectureTitle }}
                      </h3>

                      <div class="f-sb">
                        <div class="f-se f-col">
                          <p>讲座时间：{{ item.lectureTime }}</p>
                          <span>讲座地点：{{ item.lectureRegion }}</span>
                        </div>
                        <el-button round>预约报名</el-button>
                      </div>
                    </div>
                  </div>
                </el-card>
              </div>

              <div style="width: 46%" class>
                <div style="height: 400px" class="f-sb df-wrap f-ab" v-if="videoList.length > 0">
                  <!-- shadow="hover" -->
                  <div class="box-card1 hover" v-for="(item, index) in videoList" :key="index" style="">
                    <div @click="goVideo(item)" v-if="index < 4">
                      <img style="
                          height: 140px;
                          width: 230px;
                          object-fit: cover;
                          border-radius: 5px;
                        " :src="
                          '/com/file/fileDownLoad?filePath=' + item.videoImage
                        " alt :onerror="smallImgError"/>
                      <div class="video-detail" v-html="item.videoDetail"></div>
                      <!-- <p style="text-align: center; background-color: #dcefe6">
                      {{ item.videoDetail || "专业录取预测解读" }}
                    </p> -->
                    </div>
                  </div>
                </div>
                <div v-else>暂无数据</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  -->
    </div>
  </div>
</template>
<script>
import img6 from '@/assets/findSchool.png'
import img3 from '@/assets/oneToOne.png'
import img7 from '@/assets/findmajor.png'
import img4 from '@/assets/lecture.png'
import img5 from '@/assets/tianBao.png'
import img2 from '@/assets/serchscore.png'
import img1 from '@/assets/test.png'
import imgT from '@/components/imgT'
// import { getExpertPage } from "@/api/exports";
import { getSignUpLectureInfoPage, pageVideoInfo } from '@/api/lectureHall'
// import { getArticleDetail1 } from "@/api";
import {
  getAdvertisementDetail,
  getContentInfo,
  getExpertPage,
  getProvinceList,
  getStudentDetail,
  pageArticleInfo
} from '@/api/index.js'
import { getAccumulateByScore } from '@/api/IntelligentReporting'
import { saveStudentInfo } from '@/api/personalCenter.js'
import TitleBar from '@/components/TitleBar'
import Title from '@/components/Title'

export default {
  name: 'FirstPage',
  props: {},
  components: {
    Title,
    TitleBar,
    imgT
    // Swiper,
  },
  data () {
    return {
      scrImg: [], // 轮播图
      lectures: [],
      videoList: [], // 讲座视频
      dialogVisible: false,
      disabled: true,
      provinceList: [],
      options: [2021, 2020],
      companyList: [], // 公司动态
      // 公司动态请求参数
      tableQuery: {
        total: 0,
        current: 1,
        size: 10,
        type: 4,
        subjectId: 0
      },
      userForm: {
        scores: {
          accumulate: null
        }
      }, // 学生信息
      nav: [
        {
          name: '做测评',
          img_url: img1
        },
        {
          name: '查分数',
          img_url: img2
        },
        {
          name: '1对1服务',
          img_url: img3
        },
        {
          name: '听讲座',
          img_url: img4
        },
        {
          name: '智能填报',
          img_url: img5
        },
        {
          name: '找学校',
          img_url: img6
        },
        {
          name: '找专业',
          img_url: img7
        }
      ],
      bg: ['ss1', 'ss2', 'ss3'],
      img1,
      aboutText: '',
      isShow: false,
      records: [],
      contentArticle: '',
      contentImg: '',
      accumulate: '',
      checkList: []
    }
  },
  created () {
    this.getExpertlist()
  },
  updated () {
    // let img = document.getElementById("aboutus").getElementsByTagName("img");
    // img[0].style.width = "50%";
  },
  mounted () {
    // 顶部轮博图
    this.getTop()
    // 公司相关
    this.getAbout()
    // 公司动态
    this.pageArticleInfo()
    // 获取省份
    this.getProvince()
    // 高考讲堂
    this.getMetting()
    // 讲座视频
    this.getVideo()
    this.swiper(8, true, '.swiper')
    this.swiper(3, true, '.swiper-container')
    if (this.userId) {
      this.getStudentDetail()
    }
  },
  computed: {
    userId () {
      return this.$store.state.userId
    }
  },
  watch: {
    userId: {
      handler (val) {
        if (val) {
          this.getStudentDetail()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 修改成绩
    saveStudentInfo () {
      if (this.userForm.disciplineType === 3) {
        if (this.checkList.indexOf('物理') !== -1) {
          this.checkList.splice(this.checkList.indexOf('物理'), 1)
        }
        if (this.checkList.indexOf('历史') !== -1) {
          this.checkList.splice(this.checkList.indexOf('历史'), 1)
        }
        this.userForm.discipline = this.checkList.join(',')
        this.userForm.discipline = '物理,' + this.userForm.discipline
      }
      if (this.userForm.disciplineType === 4) {
        if (this.checkList.indexOf('物理') !== -1) {
          this.checkList.splice(this.checkList.indexOf('物理'), 1)
        }
        if (this.checkList.indexOf('历史') !== -1) {
          this.checkList.splice(this.checkList.indexOf('历史'), 1)
        }
        this.userForm.discipline = this.checkList.join(',')
        this.userForm.discipline = '历史,' + this.userForm.discipline
      }
      if (this.userForm.disciplineType === 0) {
        this.userForm.discipline = this.checkList.join(',')
      }
      saveStudentInfo(this.userForm).then((res) => {
        if (res.code === 1) {
          this.getStudentDetail()
          this.dialogVisible = false
        }
      })
    },
    // 学生详情
    getStudentDetail () {
      getStudentDetail({ userId: this.userId }).then((res) => {
        if (res.code === 1) {
          this.userForm = res.data || {}
          this.checkList = this.userForm.discipline.split(',')
          if (!this.userForm.scores) {
            this.userForm.scores = {
              accumulate: 0
            }
          }
          if (!this.userForm.scores.accumulate) {
            this.userForm.scores.accumulate = 0
          }
          if (
            !this.userForm.scores.accumulate &&
            this.userForm.examProvince &&
            this.userForm.province &&
            this.userForm.score &&
            this.userForm.disciplineType
          ) {
            getAccumulateByScore({
              disciplineType: this.userForm.disciplineType,
              scoreMin: this.userForm.score,
              province: {
                name: this.userForm.examProvince,
                id: this.userForm.province
              },
              year: new Date().getFullYear()
            }).then((res1) => {
              if (res1.code === 1) {
                this.accumulate = res1.data || null
              }
            })
          }
        }
      })
    },
    // 开始推荐院校
    topschool () {
      const routeData = this.$router.resolve({
        path: '/planDetail',
        query: {
          isAKeyGeneration: true
        }
      })
      window.open(routeData.href, '_blank')
    },
    // 查看志愿表
    lookvolunteer () {
      const student1 = sessionStorage.getItem('student') || {}
      const student = JSON.parse(student1)
      this.$router.push({
        path: '/personalCenter/myvolunteer',
        query: { studentId: student.studentId }
      })
    },
    // 顶部轮播图片
    async getTop () {
      const res = await getAdvertisementDetail({ id: 1 })
      if (res.code === 1) {
        this.scrImg = res.data.images
      }
      // function getimgsrc(html) {
      //   var reg = /<img.+?src=('|")?([^'"]+)('|")?(?:\s+|>)/gim;
      //   var arr = [];
      //   let tem = [];
      //   while ((tem = reg.exec(html))) {
      //     arr.push(tem[2]);
      //   }
      //   console.log(arr);
      //   return arr;
      // }
    },
    // 修改成绩
    changeScore () {
      // this.disabled = false;
      this.dialogVisible = true
    },
    // 获取省份
    async getProvince () {
      const res = await getProvinceList({})
      if (res.code === 1) {
        this.provinceList = res.data
      }
    },
    // 公司动态
    async pageArticleInfo () {
      const res = await pageArticleInfo(this.tableQuery)
      this.companyList = res.data.records
      // this.tableQuery.total = res.data.total;
    },
    // 转去公司详情
    goCompony (item) {
      this.$router.push({
        path: '/information/CompanyStatic/detail',
        query: {
          id: item.id
        }
      })
    },
    // 高考志愿服务
    goLink (val) {
      console.log(val)
      if (val === 0) {
        this.$router.push('/volunterservce/Comprehensive')
      }
      if (val === 1) {
        this.$router.push('/volunterservce/Intelligentreporting')
      }
      if (val === 2) {
        this.$router.push('/volunterservce/Comprehensive')
      }
    },

    // 关于我们
    async getAbout () {
      const res = await getContentInfo({
        contentType: 4,
        type: 3
      })
      if (res.code === 1) {
        // this.aboutText = res.data;
        const resData = res.data || {}
        if (resData.content.length > 350) {
          resData.content = resData.content.substring(0, 350) + '...'
        }
        this.contentArticle = resData.content || ''
        this.contentImg = resData.articleImage
          ? '/com/file/fileDownLoad?filePath=' + resData.articleImage
          : ''
      }
    },
    // 高考讲堂
    async getMetting () {
      const res = await getSignUpLectureInfoPage({
        lectureRegion: null,
        size: 3
      })
      if (res.code === 1) {
        this.lectures = res.data.records
      }
    },
    // 转去讲座
    goMeeting (item) {
      this.$router.push({
        path: '/lectureHall/lectureHalllecturelist/lecturedetial',
        query: {
          id: item.id
        }
      })
    },
    // 讲座视频
    async getVideo () {
      const res = await pageVideoInfo({
        size: 4,
        type: 3
      })
      if (res.code === 1) {
        const resData = res.data || {}
        this.videoList = resData.records || []
      }
    },
    // 去视频
    goVideo (item) {
      this.$router.push({
        path: '/lectureHall/lectureHallvideolist/lectureHallvideodetail',
        query: {
          id: item.id
        }
      })
    },

    linkTo (val) {
      console.log(val)
      if (val === 0) {
        this.$router.push('/volunterservce/Comprehensive')
      }
      if (val === 1) {
        this.$router.push('/dataQuery/scoresQuery')
      }
      if (val === 2) {
        this.$router.push('/volunterservce/oneServe')
      }
      if (val === 3) {
        this.$router.push('/lectureHall/lectureHalllecturelist')
      }
      if (val === 4) {
        this.$router.push('/volunterservce/Intelligentreporting')
      }
      if (val === 5) {
        this.$router.push('/dataQuery/findschool')
      }
      if (val === 6) {
        this.$router.push('/dataQuery/findProfessional')
      }
    },
    swiper (val, bol, swiper) {
      var mySwiper = new Swiper(swiper, {
        loop: true, // 循环模式选项
        slidesPerView: val,
        autoplay: {
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        },
        // 如果需要分页器
        // pagination: {
        //   el: ".swiper-pagination",
        // },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          click: function (e, val) {
          }
        }
        // 如果需要滚动条
        // scrollbar: {
        //   el: ".swiper-scrollbar",
        // },
      })
    },
    async getExpertlist () {
      const res = await getExpertPage({
        isTop: 1
      })
      if (res.code === 1) {
        this.records = res.data.records
        this.records.forEach((item) => {
          if (item.type === 1) {
            item.type = '初级专家'
          }
          if (item.type === 2) {
            item.type = '中级专家'
          }
          if (item.type === 3) {
            item.type = '高级专家'
          }
          if (item.type === 4) {
            item.type = '资深专家'
          }
        })
      }
    },
    goDetail (item) {
      this.$router.push({
        path: '/index/ExpertDetail',
        query: {
          id: item.expertId
        }
      })
    }
  }
}
</script>
<style scoped lang="less">

/deep/ .infor_cont {
  width: 100%;
  font-size: 14px;
  line-height: 1.7;
  color: #333;
  text-align: justify;
  margin-top: 0.2rem;

  p,
  span,
  div,
  font,
  section,
  h3,
  table {
    width: 100%;
    font-size: 14px !important;
    line-height: 1.7 !important;
    color: #333 !important;
    text-align: justify !important;
    margin-bottom: 0.4rem !important;
    letter-spacing: 0.02rem !important;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    margin: auto 0;
    margin-bottom: 0.4rem;
  }
}

@mycolor: rgb(132, 204, 181);
@cGreen: #1ab394;

.block {
  position: relative;
}

/deep/ .el-button.is-round {
  background: @cGreen;
  margin: 10px;
  color: white;
}

.top-mask {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
  z-index: 99;
  width: 1200px;
}

.full-width {
  width: 100%;

  &.bg-img {
    // width: 100%;
    // height: 539px;
    background-image: url("../../assets/bg1.png");
    background-position: 0 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  &.bg-img1 {
    background: url("../../assets/bg2.png");
    background-position: 0 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

.windows {
  position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  // top: 125px;
  right: 0;
  top: 40px;
  z-index: 100;

  .nav-mask-box {
    text-align: center;
    background-color: white;
    width: 350px;
    border-radius: 10px;
  }

  p {
    cursor: pointer;
    color: @cGreen;
    margin-bottom: 10px;
  }

  /deep/ .el-button.is-round {
    background: @cGreen;
    margin: 10px;
    color: white;
  }

  .windows-1 {
    // display: flex;
    width: 305px;
    background-color: #1ab394;
    opacity: 0.7;
    margin: auto 20px;
    // border: ;
    border-radius: 10px;
    padding: 10px 0px;
    cursor: pointer;

    // padding-right: 15px;
    .up {

      // div:first-child,
      // div:last-child {
      // }
      div {
        display: flex;
        justify-content: flex-start;

        span {
          padding: 5px;
          width: 80px;
        }
      }
    }

    .white-text {
      color: #FFFFFF;
    }
  }
}

.nav-2 {
  .person {
    padding: 70px 0;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .box-card {
      width: 150px;
      height: 150px;
    }
  }
}

.nav-3 {
  padding: 30px 0;
  // border-top: 1px dashed;
  // border-bottom: 1px dashed;
}

.nav-4 {

  // border-bottom: 1px dashed;
  // border-top: 1px dashed;
  .swiper-container {
    width: 100%;
    height: 200px;
  }

  a {
    color: none;
  }

  h2,
  p {
    text-align: center;
  }

  .f-row {
    justify-content: center;

    img {
      margin: 20px;
    }
  }
}

.diag {
  /deep/ .el-card__body {
    padding: 0;
  }

  h2 {
    margin: 19px auto;
  }
}

.nav-5 {

  // height: 400px;
  .f-sa {
    flex-wrap: wrap;
  }

  .nav-h {
    // height: 400px;
    // overflow: hidden;
  }

  .el-card {
    cursor: pointer;
    position: relative;
  }

  .box-card1 {
    cursor: pointer;
    position: relative;
  }

  .video-detail {
    background: #dcefe6;
    text-align: center;
    padding: 10px 0;
  }

  .title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /deep/ .el-card:hover {
    // background-color: red;
    h3 {
      color: #1ab394;
    }

    .img {
      background-image: url(../../assets/lecture/讲座报名.png);
    }
  }

  position: relative;

  .img {
    width: 70px;
    height: 60px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-image: url(../../assets/lecture/讲座.png);
  }
}

.nav-6 {
  padding-bottom: 10px;

  .f-row {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .f-row:last-child {
    border: none;
  }
}

.hover:hover {
  p {
    color: @mycolor;
  }
}

footer {
  // height: 280px;
  color: white;

  p {
    margin-bottom: 10px;
  }

  .f-sa {
    background-color: black;
  }

  a {
    color: white;
  }

  a:hover {
    color: white;
  }

  .tail {
    text-align: center;
    background-color: #cccccc;
  }
}

.main {
  // width: 1200px;
  width: 100%;
  margin-top: 30px;
  // margin: 0 auto;
}

.main-content {
  width: 1200px;
  margin: 0 auto;
}

.outbox {
  .swiper-slide {
    cursor: pointer;
    margin: 0 20px;

    img {
      width: 110px;
      height: 110px;
      border-radius: 50%;
    }
  }

  .el-card {
    width: 25%;
  }

  position: relative;
  padding: 0 50px;

  .swiper-button-prev {
    // background: url("../../assets/firstPage/7.png");
    // color: white;
  }
}

.compontitem {
  width: 45%;
  position: relative;
  cursor: pointer;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
  // display: flex;
  // display: inline-block;
}

.compontitem :nth-last-child(2) {
  // border-bottom: 1px solid #f1f1f1;
  border-bottom: none;
}

.compontitem :last-child {
  border-bottom: none;
}

.swiper-button-prev:after {
  content: "";
}

.swiper-button-next:after {
  content: "";
}

.swiper-b {
}

.aboutus {
  /deep/ p {
    text-indent: 35px;
    display: flex;
    align-items: center;
  }

  /deep/ img {
    margin-left: 20px;
    width: 50% !important;
    object-fit: cover !important;
  }
}

.right {
  height: 80px;
  line-height: 40px;
  margin-left: 10px;
  margin-left: 120px;

  .title {
    /deep/ p {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

// ul {
//   .bg,
//   .txt {
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//   }
//   .txt {
//     transition: all 0.1s ease-in-out;
//   }
//   li {
//     width: 280px;
//     height: 330px;
//     position: relative;
//     background-size: cover;
//     background-image: url("../../assets/firstPage/7.png");
//     &:hover {
//       .bg {
//         background: rgba(82, 112, 255, 0.58);
//         filter: contrast(220%);
//       }
//       .txt {
//         top: -25px;
//       }
//     }
//   }
// }
/deep/ .swiper-container {
  width: 100%;
}

.ss1 {
  color: #5a81b9;
  background-color: #5a81b9;

  span:last-child {
    background-color: #5a81b9;
  }

  background: url("../../assets/firstPage/bg1.png") no-repeat 100%;
}

.ss1,
.ss2,
.ss3 {
  p {
    background-color: #ffffff;
    margin: 30px auto;
  }

  span {
    padding: 5px;
    display: inline-block;
    color: white;
  }

  span:last-child {
    cursor: pointer;
    border: 1px;
    border-radius: 5px;
    margin-left: 30px;
    // background-color: #fff;
  }
}

.ss2 {
  span:last-child {
    background-color: #ba994d;
  }

  background-color: #ba994d;
  color: #ba994d;
  background: url("../../assets/firstPage/bg2.png") no-repeat;
}

.ss3 {
  span:last-child {
    background-color: #1da68a;
  }

  color: #1da68a;
  background: url("../../assets/firstPage/bg3.png") no-repeat;
}

/deep/ .el-form-item__content {
  height: 28px;
}

.lecture-hall {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .el-card {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.mt38 {
  margin-top: 38px;
}

.pt38 {
  padding-top: 38px;
}

.divide-line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #666;
}

.about-us-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  .content-article {
    width: 654px;
    // height: 211px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 28px;
  }

  .content-img {
    width: 485px;
    height: 324px;
    position: relative;

    .img-box {
      position: relative;
      left: 0;
      top: 0;
      display: block;
      height: 100%;
      z-index: 3;
    }

    .img-box1 {
      display: block;
      position: absolute;
      left: -10px;
      top: -10px;
      width: 454px;
      height: 324px;
      z-index: 1;
    }

    .img-box2 {
      display: block;
      position: absolute;
      left: -30px;
      top: 104px;
      width: 81px;
      height: 162px;
      z-index: 4;
    }
  }
}
</style>
